<template>
  <transition name="fade" :duration="500">
    <video-box
      v-if="boxComponent === 'video'"
      :content-url="taskData.content_url"
      :content-srcset="taskData.content_srcset"
      :end-url="taskData.end_url || ''"
      :qr-position="taskData.qr_position"
      :qr-link="taskData.url + '?qr'"
      @finished="taskData = null"
    />
    <one-third-banner-box
      v-if="boxComponent === 'one-third'"
      :content-url="taskData.content_url"
      :content-srcset="taskData.content_srcset"
      :is-video="isOneThirdVideo"
      :end-url="taskData.end_url || ''"
      :direction="oneThirdDirection"
      :duration="taskData.duration_seconds || 15"
      :qr-position="taskData.qr_position"
      :qr-link="taskData.url + '?qr'"
      @finished="taskData = null"
    />
    <full-screen-banner-box
      v-if="boxComponent === 'full-screen'"
      :content-url="taskData.content_url"
      :end-url="taskData.end_url || ''"
      :duration="taskData.duration_seconds || 15"
      @finished="taskData = null"
    />
    <burger-king-animation
      ref="anim"
    />
  </transition>
</template>

<script>
import VideoBox from '../components/VideoBox'
import OneThirdBannerBox from '../components/OneThirdBannerBox'
import FullScreenBannerBox from '../components/FullScreenBannerBox'
import BurgerKingAnimation from '../components/BurgerKingAnimation'
import layerMixin from '../mixins/layer'
import { get } from 'lodash'

export default {
  name: 'VideoPage',
  mixins: [layerMixin],
  components: {
    VideoBox,
    OneThirdBannerBox,
    FullScreenBannerBox,
    BurgerKingAnimation
  },
  data () {
    return {
      pusher: null,
      channels: {},
      taskData: null
    }
  },
  computed: {
    boxComponent () {
      if (this.taskData) {
        if (this.taskData.content_url && (this.taskData.content_type === 'video' || this.taskData.content_type === 'click')) {
          return 'video'
        } else if (this.taskData.content_url && this.taskData.content_type === 'one-third-banner') {
          return 'one-third'
        } else if (this.taskData.content_url && this.taskData.content_type === 'extension-universal'
          && this.taskData.settings && this.taskData.settings.template !== 4
        ) {
          return 'one-third'
        } else if (this.taskData.content_url && this.taskData.content_type === 'extension-universal'
          && this.taskData.settings  && this.taskData.settings.template === 4
        ) {
          return 'full-screen'
        }
      }

      return null
    },
    oneThirdDirection () {
      if (this.taskData) {
        if (this.taskData.content_url && this.taskData.content_type === 'one-third-banner') {
          return this.taskData.settings.direction
        } else if (this.taskData.content_url && this.taskData.content_type === 'extension-universal'
            && this.taskData.settings && this.taskData.settings.template !== 4
        ) {
          return {
            0: 'top',
            1: 'right',
            2: 'bottom',
            3: 'left'
          }[this.taskData.settings && this.taskData.settings.template]
        }
      }

      return null
    },
    isOneThirdVideo () {
      return this.boxComponent === 'one-third' && (get(this.taskData, 'canvas_data.media[0].type') === 'video' || get(this.taskData, 'media_type') === 'video')
    }
  },
  methods: {
    initBurgerKingClicksTracking() {
      this.channels['task-launch.' + this.userLink]
        .bind('App\\Events\\Tasks\\BurgerKingClicked', () => {
          this.$refs.anim.play()
        })
    }
  },
  mounted () {
    this.initPusher()
    this.initTaskLaunch()
    this.initBurgerKingClicksTracking()

    this.enableWindowSizeTracking('video')
  }
}
</script>

<style>
</style>