<template>
  <div id="test">
    
    <div @click="processClick">trigger err</div>
  </div>
</template>

<script>
// import QrCode from 'qrcode.vue'
import Pusher from 'pusher-js'

export default {
  name: 'TestPage',
  components: {
    // QrCode
  },
  data () {
    return {
      // 04a9eb
      lines: [],
      channels: {}
    }
  },
  computed: {

  },
  mounted () {
    this.initPusher()
  },
  methods: {
    processClick () {
      throw new Error('xxx')
    },
    subscribeChannel (name) {
      this.channels[name] = this.pusher.subscribe(name)
      return this.channels[name]
    },
    initPusher () {
      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        wssHost: process.env.VUE_APP_PUSHER_HOST,
        wsHost: process.env.VUE_APP_PUSHER_HOST,
        wssPath: process.env.VUE_APP_PUSHER_PATH,
        wsPath: process.env.VUE_APP_PUSHER_PATH,
        wssPort: process.env.VUE_APP_PUSHER_PORT,
        wsPort: process.env.VUE_APP_PUSHER_PORT,
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        // activityTimeout: 45000,
        channelAuthorization: {
          // endpoint: process.env.VUE_APP_BACKEND_HOST + '/api/common/pusher-presence-auth',
          endpoint: process.env.VUE_APP_BACKEND_HOST + '/broadcasting/auth',
          headers: {
            Authorization: 'Bearer 11|0wZgQQ6UTUTfMKfnQntaiufPI3dw28kBbSHjpGQa743b3f25'
          }
        }
      })

      // this.pusher.connection.bind('failed', () => {
      //   document.location.reload(true)
      // })

      this.addEventsLogging()

      this.subscribeChannel('presence-test-7')
      this.channels['presence-test-7']
        .bind('App\\Events\\TestEvent', (x) => {
          console.log('here', x)
        })
      
      // this.subscribeChannel('presence-layer-meta.' + this.userLink)
      // this.channels['presence-layer-meta.' + this.userLink]
      //   .bind('client-ping-is-alive', () => {
      //     this.channels['presence-layer-meta.' + this.userLink].trigger('client-pong-is-alive', {
      //       userAgent: window.navigator.userAgent
      //       // ip ?
      //     })
      //   })
      //   .bind('App\\Events\\Streamers\\LayerRefreshed', () => {
      //     document.location.reload(true)
      //   })
    },
    addEventsLogging () {
      this.pusher.connection.bind("error", function (error) {
        console.error('connection error', error)
      })

      this.pusher.connection.bind("connected", function () {
        console.warn('Connected event!')
      })

      this.pusher.connection.bind("disconnected", function () {
        console.warn('Disconnected event!')
      })

      this.pusher.connection.bind("state_change", function (states) {
        // states = {previous: 'oldState', current: 'newState'}
        console.warn('Channels current state is', states.current)
      })

      this.pusher.connection.bind("failed", function (error) {
        console.error('connection_failed', error)
      })

      window.addEventListener('online', () => {
        console.log('Window is online')
      })
      window.addEventListener('offline', () => {
        console.log('Window is offline')
      })
    }
  }
}
</script>

<style>
</style>